import './LoginRegisterStyle.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WestIcon from '@mui/icons-material/West';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Routes , Route , Link } from 'react-router-dom';
import Register from './Register';

export default function LoginRegister(){
    const registerButton = document.getElementById("register")
    const loginButton = document.getElementById("login")
    const container = document.getElementById("containter")

    function handleRegisterClicked(){
        container.classList.add("right-panel-active")
    }
    
    function handleLoginClicked(){
        container.classList.remove("right-panel-active")
    }
    return (
        <div className="containter" id="containter">
            
            <div className="form-containter register-containter">
                <form action="#">
                    <h1>Register hire.</h1>
                    <input type="text" placeholder="Name"></input>
                    <input type="email" placeholder="Email"></input>
                    <input type="password" placeholder="Password"></input>
                    <button>Register</button>
                    <span>or use your account</span>
                    <div className="soical-containter">
                        <a href="#" className="soical"><i className="lni lni-facebook-fill"><FacebookIcon/></i></a>
                        <a href="#" className="soical"><i className="lni lni-google"><GoogleIcon /></i></a>
                        <a href="#" className="soical"><i className="lni lni-linkedin-original"><LinkedInIcon /></i></a>
                    </div>
                </form>
            </div>

            <div className="form-containter login-containter">
                <form action="#">
                    <h1>Login Hear.</h1>
                    <input type="email" placeholder="Email"></input>
                    <input type="password" placeholder="Password"></input>
                    <div className="content">
                        <div className="checkbox">
                            <input type="checkbox" name="chackbox" id="checkbox"></input>
                            <label>Remember me</label>
                        </div>
                        <div className="pass-link">
                            <a href="#">Forgot password?</a>
                        </div>
                    </div>
                    <button>Login</button>
                    <span>or use your account</span>
                    <div className="soical-containter">
                        <a href="#" className="soical"><i className="lni lni-facebook-fill"><FacebookIcon/></i></a>
                        <a href="#" className="soical"><i className="lni lni-google"><GoogleIcon /></i></a>
                        <a href="#" className="soical"><i className="lni lni-linkedin-original"><LinkedInIcon /></i></a>
                    </div>
                </form>
            </div>
            <div className="overlay-containter">
                <div className="overlay">
                    <div className="overlay-panel overlay-left">
                        <h1 className="title">Hello <br /> freinds</h1> 
                        <p>if your have an account ,<br></br> login there and have fun</p>
                        <button onClick={handleLoginClicked} className="ghost" id="login">
                            Login
                            <i className="lni lni-arrow-left login"><WestIcon /> </i>
                        </button>
                    </div>
                    <div className="overlay-panel overlay-right">
                        <h1 className="title"> Start your <br /> Store now...!</h1>
                        <p>if you don't have an account yet,<br /> join us and start...!</p>
                        <Link to="/register">
                        <button style={{cursor: "pointer"}} className="ghost" id="register">
                            Register
                            <i className="lni lni-arrow-right register"><ArrowRightAltIcon /> </i>
                        </button>
                        </Link>
                    </div>
                </div>
            </div>


          

        </div>
    )
}