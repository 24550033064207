import './LoginRegisterStyle.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WestIcon from '@mui/icons-material/West';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Routes , Route , Link } from 'react-router-dom';



export default function Register(){

  return (
    <div className="containter" id="containter">
            
    <div className="form-containter register-containter">
        <form action="#">
            <h1>Register hire.</h1>
            <input type="text" placeholder="Name"></input>
            <input type="email" placeholder="Email"></input>
            <input type="password" placeholder="Password"></input>
            <button>Register</button>
            <span>or use your account</span>
            <div className="soical-containter">
                <a href="#" className="soical"><i className="lni lni-facebook-fill"><FacebookIcon/></i></a>
                <a href="#" className="soical"><i className="lni lni-google"><GoogleIcon /></i></a>
                <a href="#" className="soical"><i className="lni lni-linkedin-original"><LinkedInIcon /></i></a>
            </div>
        </form>
    </div>

    <div className="form-containter login-containter">
        <form action="#">
            <h1>Register Hear.</h1>
            <input type="email" placeholder="Email"></input>
            <input type="password" placeholder="Password"></input>
            <div className="content">
                <div className="checkbox">
                    <input type="checkbox" name="chackbox" id="checkbox"></input>
                    <label>Remember me</label>
                </div>
            </div>
            <button>Register</button>
            <span>or use your account</span>
            <div className="soical-containter">
                <a href="#" className="soical"><i className="lni lni-facebook-fill"><FacebookIcon/></i></a>
                <a href="#" className="soical"><i className="lni lni-google"><GoogleIcon /></i></a>
                <a href="#" className="soical"><i className="lni lni-linkedin-original"><LinkedInIcon /></i></a>
            </div>
        </form>
    </div>
    <div className="overlay-containter">
        <div className="overlay">
            <div className="overlay-panel overlay-left">
                <h1 className="title">Hello <br /> freinds</h1> 
                <p>if your have an account ,<br></br> login there and have fun</p>
                <button className="ghost" id="login">
                    Login
                    <i className="lni lni-arrow-left login"><WestIcon /> </i>
                </button>
            </div>
            <div className="overlay-panel overlay-right">
                <h1 className="title"> Start your <br /> Store now...!</h1>
                <p>if you don't have an account yet,<br /> join us and start...!</p>
                <Link to="/">
                <button style={{cursor: "pointer"}} className="ghost" id="register">
                    Login
                    <i className="lni lni-arrow-right register"><ArrowRightAltIcon /> </i>
                </button>
                </Link>
            </div>
        </div>
    </div>


  

</div>
  )
}