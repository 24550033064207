import logo from './logo.svg';
import './App.css';
import LoginRegister from './LoginRegister';
import { Route , Routes} from 'react-router-dom';
import Register from './Register';

function App() {
  return (
    <div className="App">
      

      <div>
        <Routes>
          <Route path='/' element={<LoginRegister />} />
          <Route path='/register' element={<Register />} />
        </Routes>
       
      </div>
    </div>
  );
}

export default App;
